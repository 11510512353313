import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (


    <div class="min-h-screen bg-white mb-4">
      <Header />

      <main class="overflow-hidden">

        <div class="bg-gray-50">
          <div class="py-24 lg:py-32">
            <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Lawn Tips</h1>
              <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">1. SHARPEN MOWER BLADES</h2>
              <p class="mt-6 text-xl text-gray-500 max-w-3xl">
                When you cut your grass with dull blades, you are actually tearing the grass instead of cutting it, which makes the top part of the grass blade turn brown. This could make your lawn look dull and patchy. The rule of thumb is to sharpen your blades after 10 hours of continuous cutting.
              </p>

              <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">2. CUT YOUR GRASS AT THE RIGHT HEIGHT AT THE RIGHT TIME</h2>
              <p class="mt-6 text-xl text-gray-500 max-w-3xl">
                Cutting your grass too short makes it very vulnerable to disease and insects. You want to cut the top 1/3 of the grass. Example: If your grass is 3″ tall, you want to cut it at 2″. During the Spring, you may have to cut every 4 -5 days and only 8-10 days in the late summer.
              </p>

              <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">3. MULCHING IS BETTER</h2>
              <p class="mt-6 text-xl text-gray-500 max-w-3xl">
                Mulching or discharging puts the grass clippings right back onto the soil where they will break down and feed the lawn acting as fertilizer.
              </p>

              <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">4. WATER YOUR LAWN AT THE RIGHT TIME</h2>
              <p class="mt-6 text-xl text-gray-500 max-w-3xl">
                Water your lawn in the early to mid-morning. Watering your lawn in the evening creates a good environment for fungus.
              </p>

              <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">5. DON’T OVER WATER</h2>
              <p class="mt-6 text-xl text-gray-500 max-w-3xl">
                Most grass only needs one inch of water per week. Over-watering opens the lawn to disease and fungus which will make the lawn look water starved and cause the lawn to have brown patches.
              </p>
            </div>
          </div>
        </div>



      </main>

      <Footer />
    </div>


  )
}
